import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";
import Link, { LinkProps } from "next/link";
import { useRouter } from "next/router";
import { forwardRef } from "react";

const isString = (value: unknown): value is string => typeof value === "string";

const isMultitenantSite = (href: string | string[] | undefined): boolean => {
  if (!href || Array.isArray(href)) {
    return false;
  }

  return (
    Boolean(href.match(/[a-zA-Z0-9-]+\.localhost/)) ||
    Boolean(href.match(/[a-zA-Z0-9-]+\.thelastweekin\.dev/)) ||
    Boolean(href.match(/[a-zA-Z0-9-]+\.tlwi\.dev/))
    // Boolean(href.match(/[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+okeeffed\.vercel\.app/))
  );
};

const homeRoutes = ["/", "/about", "/home"];

/**
 * Helper for multi-tenant app previews in Vercel.
 */
function FormattedLinkImpl(
  { href, ...props }: React.PropsWithChildren<LinkProps>,
  ref: React.Ref<HTMLAnchorElement>
) {
  const {
    query: { site },
  } = useRouter();
  if (!isString(href)) {
    throw Error(`FormattedLink href must be a string, got ${typeof href}`);
  }

  // Handle sites on the preview URLs
  if (
    process.env.NEXT_PUBLIC_PREVIEW_URLS &&
    (href.startsWith("/") || isMultitenantSite(site))
  ) {
    // If already a preview url, return the link as is.
    // Else if it isn't the home site and matches a home route, return the link as is.
    if (href.startsWith("/_preview") || (homeRoutes.includes(href) && !site)) {
      return <Link ref={ref} href={href} {...props} />;
    }

    // If the site is a multitenant site, we need to update the href on preview
    if (!href.startsWith("/")) {
      const url = new URL(href);
      const [hostname] = url.hostname.split(".");

      // If there is no host name and starts with /, assume it's base site
      if (!hostname) {
        return <Link ref={ref} href={href} {...props} />;
      }

      // Other websites can be assumed to be a preview URL
      const updatedHref = `/_preview/${site}${href}`;
      return <Link ref={ref} href={updatedHref} {...props} />;
    }

    // The fallback is just to assume it's a preview URL
    const fallbackHref = `/_preview/${site}${href}`;
    return <Link ref={ref} href={fallbackHref} {...props} />;
  } else {
    if (!href.startsWith("/")) {
      // TODO: Force cast right now, but we should fix this
      return (
        <ChakraLink ref={ref} href={href} {...(props as ChakraLinkProps)} />
      );
    }

    return <Link ref={ref} href={href} {...props} />;
  }
}

export const FormattedLink = forwardRef(FormattedLinkImpl);
