/* eslint-disable react-hooks/rules-of-hooks */
import {
  AspectRatio,
  Box,
  Button,
  Container,
  Divider,
  Heading,
  HStack,
  Icon,
  Link,
  ListItem,
  OrderedList,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { HiExternalLink } from "@react-icons/all-files/hi/HiExternalLink";
import kebabCase from "lodash/kebabCase";
// import { serialize } from "next-mdx-remote/serialize";
// import dynamic from "next/dynamic";
import NextImage from "next/image";
import { useRouter } from "next/router";
import { MDXRemote } from "next-mdx-remote";
import React from "react";
// import { Waypoint } from "react-waypoint";
import { SignUp } from "ui/src/components/SignUp";
import { useBlogContent } from "ui/src/hooks/BlogContentContext";
import { useSelectedImage } from "ui/src/hooks/useSelectedImage";

import { Adsense } from "../AdSense/Adsense";

// const SignUp = dynamic(
//   () => import("ui/src/components/SignUp").then((mod) => mod.SignUp),
//   {
//     loading: () => <Skeleton height={8} width={240} />,
//   }
// );
// const CodeBlock = dynamic(() =>
//   import("ui").then((mod) => mod.CodeBlock, { ssr: false })
// );

function defineSrc(src: string) {
  return src.replace("..", "");
}

// function getLanguage(className: string) {
//   switch (className) {
//     case "language-s":
//     case "language-bash":
//     case "language-shell":
//       return "language-shell";
//     default:
//       return className;
//   }
// }

// TODO: Abstract and replace type with Publication
function getSiteColors(site: string) {
  switch (site) {
    case "typescript":
      return {
        color: "blue.700",
        bg: "blue.500",
      };
    case "golang":
      return {
        color: "teal.700",
        bg: "teal.500",
      };
    case "python":
      return {
        color: "orange.700",
        bg: "orange.500",
      };
    case "rust":
      return {
        color: "pink.700",
        bg: "pink.500",
      };
    case "weekly":
    default:
      return {
        color: "purple.700",
        bg: "purple.500",
      };
  }
}

type HTMLProps = React.HTMLAttributes<HTMLElement> & {
  // used for MathBlock
  title?: string;
};
const components: Record<string, React.FC<HTMLProps>> = {
  h1: (props) => <Heading fontWeight="bold" size="3xl" {...props} />,
  h2: (props) => {
    const id = kebabCase(props.children as string);
    return (
      <Heading
        fontWeight="bold"
        id={id}
        size="2xl"
        lineHeight="normal"
        mb={8}
        {...props}
      />
    );
  },
  h3: (props) => <Heading fontWeight="bold" size="xl" mb={8} {...props} />,
  p: (props) => (
    <Text fontSize={["lg", "xl"]} mb={8} lineHeight="tall" {...props} />
  ),
  img: (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { onOpen, setImage } = useSelectedImage((state: any) => ({
      onOpen: state.onOpen,
      setImage: state.setImage,
    }));

    // @ts-expect-error: src added on
    const src = defineSrc(props.src);
    return (
      <VStack
        justifyContent="center"
        _hover={{
          cursor: {
            sm: "initial",
            md: "pointer",
          },
        }}
        onClick={() => {
          setImage(src);
          onOpen();
        }}
        mb={8}
      >
        {/* @ts-expect-error: image props forced */}
        <NextImage
          {...props}
          src={src}
          // objectFit=""
          width={1000}
          height={600}
          quality={72}
        />
        <Text fontSize="md" fontStyle="italic">
          {/* @ts-expect-error: alt props forced */}
          {props.alt}
        </Text>
      </VStack>
    );
  },
  a: ({ children, ...rest }) => (
    <Link
      color={useColorModeValue("blue.600", "blue.200")}
      fontWeight="bold"
      rel="noreferrer noopener"
      target="_blank"
      textDecoration="none"
      borderBottomWidth="6px"
      borderBottomColor={useColorModeValue("blue.100", "blue.600")}
      _hover={{
        borderBottomColor: useColorModeValue("blue.200", "blue.400"),
        color: useColorModeValue("blue.900", "blue.100"),
      }}
      _focus={{
        borderBottomColor: useColorModeValue("blue.200", "blue.400"),
        color: useColorModeValue("blue.900", "blue.100"),
      }}
      {...rest}
    >
      {children}
      <Icon ml={1}>
        <HiExternalLink />
      </Icon>
    </Link>
  ),
  // code: (props) => {
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   const [displayCode, setDisplayCode] = React.useState(false);
  //   return (
  //     <div>
  //       {/* @ts-ignore: weird type error */}
  //       <Waypoint onEnter={() => setDisplayCode(true)} topOffset="-400px" />
  //       <CodeBlock
  //         {...props}
  //         // @ts-expect-error: className added on
  //         className={getLanguage(props.className)}
  //         displayCode={displayCode}
  //       />
  //     </div>
  //   );
  // },
  ul: (props) => (
    <Box px={6} mb={8}>
      <UnorderedList spacing={8} {...props} />
    </Box>
  ),
  li: (props) => (
    <ListItem {...props} fontSize="xl" mb={4}>
      {props.children}
    </ListItem>
  ),
  ol: (props) => (
    <Box px={6} mb={8}>
      <OrderedList spacing={8} {...props} />
    </Box>
  ),
  blockquote: (props) => (
    <Box my={12} paddingLeft={4} borderLeftWidth={4} borderLeftColor="gray">
      <Text as="blockquote" fontStyle="italic" color="gray">
        {React.Children.map(props.children, (child) =>
          React.cloneElement(child as React.ReactElement<any, string>, {
            py: 2,
            fontSize: "2xl",
          })
        )}
      </Text>
    </Box>
  ),
  table: (props) => (
    <Table
      boxShadow="md"
      borderRadius="md"
      overflow="hidden"
      mb={8}
      {...props}
    />
  ),
  tbody: (props) => <Tbody {...props} />,
  td: (props) => <Td {...props} />,
  tr: (props) => <Tr {...props} />,
  th: (props) => <Th bgColor="gray.100" {...props} />,
  thead: (props) => <Thead {...props} />,
  tfoot: (props) => <Tfoot {...props} />,
  Adsense: () => <Adsense />,
};

components.h1.displayName = "h1";
components.h2.displayName = "h2";
components.h3.displayName = "h3";
components.p.displayName = "p";
components.img.displayName = "img";
components.a.displayName = "a";
// components.code.displayName = "code";
components.ul.displayName = "ul";
components.li.displayName = "li";
components.ol.displayName = "ol";
components.blockquote.displayName = "blockquote";
components.table.displayName = "table";
components.tbody.displayName = "tbody";
components.td.displayName = "td";
components.tr.displayName = "tr";
components.th.displayName = "th";
components.thead.displayName = "thead";
components.tfoot.displayName = "tfoot";
components.Adsense.displayName = "Adsense";

export function BlogArticle() {
  const { isFallback } = useRouter();
  const { frontMatter, source, site } = useBlogContent();
  const { push } = useRouter();

  const allComponents = {
    ...components,
  };

  if (isFallback) {
    return (
      <Container as="article" maxW="3xl">
        <VStack align="stretch" spacing={4}>
          <Skeleton height={300} />
          <Skeleton height={24} />
          <HStack>
            <Skeleton height={6} width={100} />
            <Skeleton height={6} width={100} />
          </HStack>
          <Skeleton height={16} />
          <Skeleton height={6} />
          <Skeleton height={6} />
          <Skeleton height={6} />
          <Skeleton height={16} />
          <Skeleton height={6} />
          <Skeleton height={6} />
          <Skeleton height={6} />
        </VStack>
      </Container>
    );
  }

  const { title, tags } = frontMatter;

  const siteColors = getSiteColors(site);

  return (
    <Container
      as="article"
      maxW="3xl"
      px={{
        base: 0,
        md: 4,
      }}
    >
      <VStack align="stretch" spacing={8}>
        <AspectRatio ratio={16 / 9}>
          <VStack
            w="full"
            h="627"
            bg="black"
            spacing={{
              base: 4,
              md: 8,
            }}
            p={4}
            textAlign="center"
          >
            <Box bgColor={siteColors.bg}>
              <Heading
                as="h1"
                fontSize={{
                  base: "2xl",
                  md: "5xl",
                }}
                color="white"
                fontWeight="bold"
                p={2}
                mb={{
                  base: 2,
                  md: 3,
                }}
                bgColor={siteColors.color}
              >
                {title}
              </Heading>
            </Box>
            <Heading
              as="h2"
              fontSize={{
                base: "lg",
                sm: "xl",
                md: "2xl",
              }}
              color="white"
              fontWeight="bold"
            >
              thelastweekin.dev
            </Heading>
          </VStack>
        </AspectRatio>
        {tags && (
          <HStack>
            {tags.split(",").map((tag) => (
              <Button
                size="xs"
                key={tag}
                onClick={() => void push(`/?tags=${tag}`)}
              >
                {tag}
              </Button>
            ))}
          </HStack>
        )}

        <div className="wrapper">
          <MDXRemote
            // @ts-expect-error: untyped MDXRemote file
            {...source}
            // @ts-expect-error: untyped MDXRemote file
            components={allComponents}
            lazy
          />
        </div>

        <Divider />
        <SignUp />
      </VStack>
    </Container>
  );
}
