import {
  Button,
  Heading,
  HStack,
  Input,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import Image from "next/image";
import React from "react";
import { useForm } from "react-hook-form";
import { useStore } from "ui";
import { z } from "zod";

import logo from "./assets/tlwi-dev-new-logo.svg";

const schema = z.object({
  email: z.string().email(),
});

interface MainHero {
  byline: string;
}
export const MainHero: React.FC<MainHero> = ({ byline }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });
  const [isSubscribing, setIsSubscribing] = React.useState(false);
  const [isSubscribed, setSubscribed] = useStore((state) => [
    state.isSubscribed,
    state.setSubscribed,
  ]);
  const toast = useToast();

  const onSubmit = async (data: any) => {
    try {
      setIsSubscribing(true);
      await axios.post("/api/subscribe", data);
      setSubscribed();
      toast({
        title: "Subscribed",
        position: "top-right",
        description: "You have been subscribed to the newsletter.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      console.error(e);
      toast({
        title: "Subscription failed",
        position: "top-right",
        description: "There was a problem subscribing. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsSubscribing(false);
    }
  };

  return (
    <VStack spacing={8} mb={16}>
      <Image alt="Logo" src={logo} width={240} height={130} priority />

      <Heading size="2xl" textAlign="center" lineHeight="normal">
        🦄 The Last Week In Dev 🦄
      </Heading>
      <Text fontSize="2xl" maxW="xl" textAlign="center">
        {byline}
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HStack
          w="full"
          maxW="xl"
          flexDir={["column", "row"]}
          alignItems="center"
          spacing={[0, 2]}
        >
          <Input
            fontFamily="heading"
            fontSize="xl"
            letterSpacing={1.5}
            placeholder="hello@coolperson.dev"
            isInvalid={Boolean(errors.email)}
            isDisabled={isSubscribed || isSubscribing}
            {...register("email", { required: true })}
          />
          <Button
            type="submit"
            fontFamily="heading"
            fontSize="xl"
            w="xs"
            isLoading={isSubscribing}
            isDisabled={isSubscribed}
            loadingText="Subscribbling..."
          >
            {isSubscribed ? "Subscribed" : "Subscribe"}
          </Button>
        </HStack>
      </form>
      {errors.email && (
        <Text color="red.500">
          A valid email is required to subscribe and hear cool things
        </Text>
      )}
      <Text fontSize="lg" maxW="xl" textAlign="center">
        Subscribe to my personal newsletter to get updates on TheLastWeekIn.Dev
        + more!
      </Text>
    </VStack>
  );
};
