import {
  Box,
  Container,
  Grid,
  GridItem,
  Link,
  Skeleton,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import kebabCase from "lodash/kebabCase";
import NextLink from "next/link";
import { useRouter } from "next/router";
import React from "react";

import { Adsense, SocialMediaShare, useBlogContent } from "../..";

import { BlogArticle } from "./BlogArticle";

function Headings({ headings }: { headings: string[] }) {
  const { isFallback } = useRouter();

  if (isFallback) {
    return (
      <VStack
        pt={0}
        spacing={4}
        width="full"
        alignItems="flex-start"
        maxW="240px"
      >
        <Skeleton height={8} width={240} />
        <Skeleton height={8} width={240} />
        <Skeleton height={8} width={240} />
        <Skeleton height={8} width={240} />
        <Skeleton height={8} width={240} />
        <Skeleton height={8} width={240} />
        <Skeleton height={8} width={240} />
        <Skeleton height={8} width={240} />
      </VStack>
    );
  }

  return (
    <VStack pt={0} alignItems="flex-start" maxW="240px">
      {headings.map((heading) => (
        <Link
          key={heading}
          href={`#${kebabCase(heading)}`}
          as={NextLink}
          fontWeight="semibold"
          variant="ghost"
        >
          {heading}
        </Link>
      ))}
    </VStack>
  );
}

function BlogDesktop() {
  const { headings, frontMatter, slug, site } = useBlogContent();

  return (
    <Grid templateColumns="repeat(5, 1fr)" gap={0}>
      <GridItem
        colStart={{
          md: 0,
          lg: 1,
        }}
        colEnd={{
          md: 0,
          lg: 2,
        }}
        position="relative"
        display={{
          base: "none",
          md: "none",
          lg: "block",
        }}
      >
        <Box position="sticky" top={16} pl={2}>
          <Box pl={6} mb={20}>
            <SocialMediaShare
              title={
                frontMatter?.title
                  ? `${frontMatter.title} - TLWI Blog`
                  : "TLWI Blog"
              }
              url={`https://thelastweekin.dev/${site}/blog/${slug}`}
            />
          </Box>
          <Adsense />
        </Box>
      </GridItem>
      <GridItem
        colStart={{
          sm: 1,
          md: 1,
          lg: 2,
        }}
        colEnd={{
          sm: 6,
          md: 5,
        }}
      >
        <BlogArticle />
      </GridItem>
      <GridItem
        colStart={5}
        colEnd={6}
        display={{
          sm: "none",
          md: "block",
        }}
        position="relative"
      >
        <Box position="sticky" top={16}>
          <Container maxW="sm">
            <VStack>
              <Box mb={12}>
                <Headings headings={headings} />
              </Box>
            </VStack>
          </Container>
          <Adsense />
        </Box>
      </GridItem>
    </Grid>
  );
}

function BlogMobile() {
  return (
    <Box>
      <BlogArticle />
    </Box>
  );
}

export function BlogContent() {
  const isDesktop = useBreakpointValue({ base: false, sm: true });

  return isDesktop ? <BlogDesktop /> : <BlogMobile />;
}
