import { Heading, Link, useColorModeValue, VStack } from "@chakra-ui/react";
import NextLink from "next/link";

const newsletters = [
  {
    title: "Rust",
    linkKey: "rust",
  },
  {
    title: "Golang",
    linkKey: "golang",
  },
  {
    title: "TypeScript",
    linkKey: "typescript",
  },
  {
    title: "Python",
    linkKey: "python",
  },
  {
    title: "Developer Weekly",
    linkKey: "weekly",
  },
];

export interface NewslettersProps {
  heading?: string;
}

export function Newsletters({ heading = "Newsletters" }: NewslettersProps) {
  const headingColor = useColorModeValue("black", "white");
  const listFontColor = useColorModeValue("blue.600", "blue.200");

  return (
    <VStack alignItems="flex-start" spacing={4} pb={24}>
      <Heading size="xl" color={headingColor}>
        {heading}
      </Heading>
      {newsletters.map(({ title, linkKey }) => (
        <Link
          as={NextLink}
          color={listFontColor}
          fontSize="2xl"
          key={title}
          href={`/${linkKey}`}
        >
          {title}
        </Link>
      ))}
    </VStack>
  );
}
