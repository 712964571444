import {
  Box,
  Button,
  Heading,
  HStack,
  Input,
  useColorModeValue as mode,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { HiShieldCheck } from "@react-icons/all-files/hi/HiShieldCheck";
import * as React from "react";

interface INewsletterInput {
  title: string;
  subtitle: string;
  placeholder: string;
  footer: string;
  buttonLabel: string;
}

export const NewsletterInput: React.FC<INewsletterInput> = ({
  title,
  subtitle,
  placeholder,
  footer,
  buttonLabel,
}) => {
  const bgColor = useColorModeValue("white", "gray.700");

  return (
    <Box as="section" py="12">
      <Box
        textAlign="center"
        shadow="lg"
        maxW={{ base: "xl", md: "5xl" }}
        mx="auto"
        px={{ base: "6", md: "8" }}
        py="12"
        rounded="lg"
        bgColor={bgColor}
      >
        <Box maxW="md" mx="auto">
          <Text
            color={mode("green.600", "green.400")}
            fontWeight="bold"
            fontSize="sm"
            letterSpacing="wide"
          >
            {title}
          </Text>
          <Heading mt="4" fontWeight="extrabold">
            {subtitle}
          </Heading>
          <Box mt="6">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                // your subscribe logic here
                window.alert(`subscribed`);
              }}
            >
              <Stack>
                <Input
                  aria-label={placeholder}
                  placeholder={placeholder}
                  rounded="base"
                />
                <Button
                  type="submit"
                  w="full"
                  colorScheme="blue"
                  size="lg"
                  textTransform="uppercase"
                  fontSize="sm"
                  fontWeight="bold"
                >
                  {buttonLabel}
                </Button>
              </Stack>
            </form>
            <HStack mt={4} spacing={1} justifyContent="center">
              <Box
                aria-hidden
                as={HiShieldCheck}
                display="inline-block"
                marginEnd="2"
                fontSize="lg"
                color={mode("green.600", "green.400")}
              />
              <Text color={mode("gray.600", "gray.400")} fontSize="sm" mt="5">
                {footer}
              </Text>
            </HStack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
