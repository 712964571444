import NextLink from "next/link";
import React from "react";

import { User } from "../../types/user";

type Props = {
  data: User;
};

export const ListItem = ({ data }: Props) => (
  <NextLink href="/users/[id]" as={`/users/${data.id}`}>
    {data.id}: {data.name}
  </NextLink>
);
