import {
  IconButton,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FaHackerNewsSquare } from "@react-icons/all-files/fa/FaHackerNewsSquare";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaRedditSquare } from "@react-icons/all-files/fa/FaRedditSquare";
import { FaShareAlt } from "@react-icons/all-files/fa/FaShareAlt";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import NextLink from "next/link";
interface ISocialMediaShare {
  title: string;
  url: string;
  isMobile?: boolean;
}

export const SocialMediaShare: React.FC<ISocialMediaShare> = ({
  title,
  url,
  isMobile = false,
}) => {
  const shareLink = encodeURI(url);
  const shareText = encodeURI(title);

  const twitterShare = `https://twitter.com/intent/tweet?text=${shareText}&url=${shareLink}`;
  const linkedInShare = `https://www.linkedin.com/shareArticle?mini=true&url=${shareLink}&title=${shareText}&summary=${shareText}&source=${shareLink}`;
  const redditShare = `https://reddit.com/submit/?url=${shareLink}&resubmit=true&title=${shareText}`;
  const hackerNewsShare = `https://news.ycombinator.com/submitlink?u=${shareLink}&t=${shareText}`;

  const openShare = (link: string) => {
    // @ts-ignore: window is defined
    window.open(link, "popup", "width=600,height=600");
    return false;
  };

  return (
    <VStack
      position={isMobile ? "static" : "inherit"}
      spacing={4}
      maxW={isMobile ? undefined : 18}
    >
      <IconButton
        href={linkedInShare}
        p={4}
        fontSize="4xl"
        as={NextLink}
        variant="ghost"
        colorScheme="blue"
        aria-label="Share to LinkedIn"
        icon={<FaLinkedin />}
        target="popup"
        onClick={() => openShare(linkedInShare)}
      />
      <IconButton
        href={twitterShare}
        p={4}
        fontSize="4xl"
        as={NextLink}
        variant="ghost"
        colorScheme="blue"
        aria-label="Share to Twitter"
        icon={<FaTwitter />}
        target="popup"
        onClick={() => openShare(twitterShare)}
      />
      <IconButton
        href={redditShare}
        p={4}
        fontSize="4xl"
        as={NextLink}
        variant="ghost"
        colorScheme="blue"
        aria-label="Share to Reddit"
        icon={<FaRedditSquare />}
        target="popup"
        onClick={() => openShare(redditShare)}
      />
      <IconButton
        href={hackerNewsShare}
        p={4}
        fontSize="4xl"
        as={NextLink}
        variant="ghost"
        colorScheme="blue"
        aria-label="Share to Hacker News"
        icon={<FaHackerNewsSquare />}
        target="popup"
        onClick={() => openShare(hackerNewsShare)}
      />
    </VStack>
  );
};

export const MobileSocialMediaShare: React.FC<ISocialMediaShare> = ({
  title,
  url,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <IconButton
          display={{
            base: "inline-flex",
            lg: "none",
          }}
          fontSize="2xl"
          as={Link}
          variant="ghost"
          colorScheme="blue"
          aria-label="Share to Twitter"
          icon={<FaShareAlt />}
          rel="noreferrer"
          target="_blank"
        />
      </PopoverTrigger>
      <PopoverContent w={28} mr={2}>
        <PopoverArrow />
        <PopoverBody>
          {isOpen && <SocialMediaShare title={title} url={url} isMobile />}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
