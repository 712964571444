import * as React from "react";

import type { FrontMatter } from "../types/frontmatter";

interface BlogContent {
  source: string;
  headings: string[];
  frontMatter: FrontMatter;
  // TODO: This should be type `Publication`.
  site: string;
  slug: string;
}
const BlogContentContext = React.createContext<BlogContent | undefined>(
  undefined
);

interface BlogContentProviderProps {
  value: BlogContent;
  children: React.ReactNode;
}

/**
 * @param {BlogContentProviderProps} props Props to pass to provider
 * @param {BlogContent} props.value Initial state for context
 * @returns {React.ReactElement} Context Provider
 */
export const BlogContentProvider: React.FC<BlogContentProviderProps> = ({
  value,
  ...props
}) => {
  return <BlogContentContext.Provider value={value} {...props} />;
};

/**
 * Hook to access state
 *
 * @returns {BlogContent} hook to access state and dispatch
 */
export function useBlogContent(): BlogContent {
  const context = React.useContext(BlogContentContext);

  if (!context) {
    throw new Error(
      `useBlogContent must be rendered within the BlogContentProvider`
    );
  }

  return context;
}
